import { ChevronRightIcon } from "@chakra-ui/icons"
import { Box, Button, Center, chakra, HStack, Link } from "@chakra-ui/react"
import { Tag } from "@~/common/lib/microcms/media/types/tags"
import { pagesPath } from "lib/$path"
import { PageTitle } from "media/components/common/PageTitle"
import NextLink from "next/link"

export type PopularTagsSectionProps = {
  id?: string
  tags: Pick<Tag, "id" | "name">[]
}

export const PopularTagsSection = ({
  tags,
  ...props
}: PopularTagsSectionProps) => {
  return (
    <chakra.section
      bgColor="moneiro-color-fill-gray-basic"
      px={{ base: 3, md: 0 }}
      pt={{ base: 8, md: 10 }}
      pb={{ base: 8, md: 14 }}
    >
      <PageTitle mainText="人気のタグ" />
      <HStack
        wrap="wrap"
        gap={2}
        maxW="container.content"
        mx="auto"
        justify={{ base: "flex-start", md: "center" }}
        px={{ base: 2, md: 0 }}
      >
        {tags.map((t) => (
          <NextLink
            key={t.id}
            href={pagesPath.media.tag._id(t.id).$url()}
            passHref
            legacyBehavior
          >
            <Link
              color="moneiro-color-main-orange"
              bgColor="white"
              border="solid 1px"
              borderColor="moneiro-color-line-gray-basic"
              rounded="30px"
              px={3}
              py={1}
              textStyle={{ base: "12-reg", md: "14-reg" }}
              _hover={{
                color: "white",
                bgColor: "moneiro-color-main-orange",
                borderColor: "moneiro-color-main-orange",
              }}
            >
              #{t.name}
            </Link>
          </NextLink>
        ))}
      </HStack>
      <Center mt={{ base: 4, md: 5 }}>
        <NextLink href={pagesPath.media.tag.$url()} passHref legacyBehavior>
          <Button
            as="a"
            size="lg"
            w="full"
            maxW="315px"
            colorScheme="secondary"
            justifyContent="space-between"
            leftIcon={<Box boxSize="24px" />}
            rightIcon={<ChevronRightIcon boxSize="24px" />}
          >
            タグ一覧へ
          </Button>
        </NextLink>
      </Center>
    </chakra.section>
  )
}
