import { ChevronRightIcon } from "@chakra-ui/icons"
import {
  Box,
  Button,
  Center,
  chakra,
  HStack,
  Image,
  SimpleGrid,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react"
import { imgixURL } from "@~/common/lib/imgix"
import { Category } from "@~/common/lib/microcms/media/types/categories"
import { pagesPath } from "lib/$path"
import { useRecommendedEntriesByCategory } from "media/hooks/useRecommendedEntriesByCategory"
import NextLink from "next/link"
import { ArticleSimpleCard } from "../common/ArticleSimpleCard"
import { PageTitle } from "../common/PageTitle"

export type CaregoryArticleSectionProps = {
  categories: Pick<Category, "id" | "name" | "icon">[]
  id?: string
}

export const CategoryArticleSection = ({
  categories,
  ...props
}: CaregoryArticleSectionProps) => {
  const { data, targetCategoryId, setTargetCategoryId } =
    useRecommendedEntriesByCategory(categories[0].id)

  return (
    <chakra.section
      bgColor="moneiro-color-white"
      px={3}
      py={{ base: 8, md: 10 }}
    >
      <VStack maxW="container.content" mx="auto">
        <PageTitle id={props.id} mainText="カテゴリー" />
        <Box
          maxW={{ base: "container.content", md: "690px" }}
          w="full"
          overflowX="scroll"
          sx={{
            "&::-webkit-scrollbar": {
              display: "none",
            },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          <Wrap
            spacing={2}
            align="center"
            w={{ base: "max-content", md: "full" }}
          >
            {categories.map((category) => {
              const isActive = targetCategoryId === category.id
              return (
                <WrapItem
                  key={category.id}
                  onClick={() => setTargetCategoryId(category.id)}
                  bgColor={
                    isActive
                      ? "moneiro-color-main-orange"
                      : "moneiro-color-fill-gray-basic"
                  }
                  textStyle="14-bold"
                  color={isActive ? "white" : "#8b8b8b"} // 非アクティブ時のカラーはアイコンのカラーと合わせてます
                  px="10px"
                  py="6px"
                  borderRadius="5px"
                  _hover={{
                    cursor: "pointer",
                  }}
                >
                  <HStack>
                    {category.icon && (
                      <Image
                        src={imgixURL(
                          category.icon.url,
                          `w=${category.icon.width}&h=${category.icon.height}&fit=crop&crop=faces`
                        )}
                        w={{
                          base: `16px`,
                          md: `16px`,
                        }}
                        alt=""
                        flexShrink={0}
                        // NOTE: アイコンはmicroCMSから取得しているため、filterとmixBlendModeで色を変えています
                        filter={isActive ? "invert(1)" : "contrast(0)"}
                        mixBlendMode={isActive ? "color-dodge" : undefined}
                      />
                    )}

                    <chakra.span>{category.name}</chakra.span>
                  </HStack>
                </WrapItem>
              )
            })}
          </Wrap>
        </Box>

        {data ? (
          data.length > 0 ? (
            <SimpleGrid
              columns={{ base: 1, md: 2 }}
              spacing={3}
              mt={4}
              w="full"
            >
              {data.map((entry) => (
                <ArticleSimpleCard entry={entry} key={entry.id} noCategory />
              ))}
            </SimpleGrid>
          ) : (
            <Center py={2}>
              <chakra.p>記事が見つかりません</chakra.p>
            </Center>
          )
        ) : (
          <Center py={2}>
            <chakra.p>記事を取得中です</chakra.p>
          </Center>
        )}
      </VStack>
      <Center mt={5}>
        <NextLink
          href={pagesPath.media.category.$url()}
          passHref
          legacyBehavior
        >
          <Button
            as="a"
            size="lg"
            w="full"
            maxW="315px"
            colorScheme="secondary"
            justifyContent="space-between"
            leftIcon={<Box boxSize="24px" />}
            rightIcon={<ChevronRightIcon boxSize="24px" />}
          >
            カテゴリ一覧へ
          </Button>
        </NextLink>
      </Center>
    </chakra.section>
  )
}
