import { staticPath_assets_media_common_ogp_png } from "lib/$asset-path"
import { extendTheme } from "@chakra-ui/react"
import { MetaTag } from "@~/common/components"
import { PublicBaseURL } from "@~/common/lib/constants"
import { Author, CMSClient, MoneiroConfig } from "@~/common/lib/microcms/media"
import { createGetMediaPageLayout } from "components/layout/MediaPageLayout"
import { theme as baseTheme } from "components/system/theme"

import { setPageAttr } from "lib/page"
import { InfoSection } from "media/components/common/InfoSection"
import {
  AuthorsSection,
  AuthorsSectionProps,
} from "media/components/index/AuthorsSection"
import {
  CaregoryArticleSectionProps,
  CategoryArticleSection,
} from "media/components/index/CategoryArticleSection"
import {
  FeatureArticleSection,
  FeatureArticleSectionProps,
} from "media/components/index/FeatureArticleSection"
import {
  LatestArticleSection,
  LatestArticleSectionProps,
} from "media/components/index/LatestArticleSection"
import { MediaPageTitle } from "media/components/index/MediaPageTitle"
import {
  PopularTagsSection,
  PopularTagsSectionProps,
} from "media/components/index/PopularTagsSection"
import { GetStaticProps } from "next"

export type MediaPageProps = {
  latestEntries: LatestArticleSectionProps["latestEntries"]
  categories: CaregoryArticleSectionProps["categories"]
  tags: PopularTagsSectionProps["tags"]
  featureArticles: FeatureArticleSectionProps["entries"]
  authorsData: AuthorsSectionProps
}

export default function MediaPage({ ...props }: MediaPageProps) {
  return (
    <>
      <MetaTag
        title="マネイロメディア"
        titleSuffix="｜資産運用とお金の情報サイト"
        description="マネイロメディアは資産運用や投資など、お金の基本や増やし方について、おすすめ情報を発信するメディアです。証券アナリストやファイナンシャルアドバイザー（IFA）ほか、専門家が執筆・解説しています。"
        canonical={`${PublicBaseURL}/media`}
        ogImage={`${PublicBaseURL}${staticPath_assets_media_common_ogp_png}`}
      />

      {/* タイトル */}
      <MediaPageTitle />

      {/* 最新記事 */}
      <LatestArticleSection id="latest" latestEntries={props.latestEntries} />

      {/* 特集 */}
      <FeatureArticleSection id="feature" entries={props.featureArticles} />

      {/* カテゴリ */}
      <CategoryArticleSection id="categories" categories={props.categories} />

      {/* 人気のタグ */}
      <PopularTagsSection id="tags" tags={props.tags} />

      {/* 執筆者・監修者 */}
      {props.authorsData.authors.length > 0 && (
        <AuthorsSection id="authors" {...props.authorsData} />
      )}

      {/* 共通フッター */}
      <InfoSection id="info" hideLinkToTopButton mt={0} />
    </>
  )
}

export const getStaticProps: GetStaticProps<MediaPageProps> = async (ctx) => {
  // MN-617: 最終調整時にコメント解除
  // メディアトップページ設定(著者一覧)取得
  const moneiroConfigPromise: Promise<MoneiroConfig> =
    CMSClient.current.getSingularItem("config", {
      fields: [
        "media_top_authors.id",
        "media_top_authors.name",
        "media_top_authors.icon",
        "media_top_authors.title",
      ],
      depth: 1,
    }) as any // CMSClientの型がおかしいので回避

  // 最新記事取得
  const latestArticleSectionPromise = CMSClient.current.listItems("entry", {
    limit: 7,
    fields: [
      "id",
      "title",
      "eyecatch",
      "publishedAt",
      "createdAt",
      "tag.id",
      "tag.name",
      "authors.name",
      "tags.id",
      "tags.name",
      "category.id",
      "category.name",
    ],
    orders: "-publishedAt",
  })

  // 特集記事取得
  const featureArticleSectionPromise = CMSClient.current.listItems(
    "feature-entry",
    {
      filters: "thumbnail[exists]",
      fields: ["id", "title", "description", "thumbnail"],
      limit: 2,
    }
  )

  const categoriesPromise = CMSClient.current.listItems("categories", {
    limit: 100, // /apps/main/pages/media/article/index.tsxを参考に
    fields: ["id", "name", "icon"],
  })

  const tagsPromise = CMSClient.current.listItems("tags", {
    limit: 100, // デフォルト10件なのでページネーションを行いたいが一旦100件決め打ち
    fields: ["id", "name"],
  })

  const [
    moneiroConfig,
    latestArticleSectionData,
    featureArticleSectionData,
    categories,
    tags,
  ] = await Promise.all([
    moneiroConfigPromise,
    latestArticleSectionPromise,
    featureArticleSectionPromise,
    categoriesPromise,
    tagsPromise,
  ])

  return {
    props: {
      authorsData: {
        authors: moneiroConfig.media_top_authors.filter(
          (a: Author) => !!a.icon
        ),
      },
      latestEntries: latestArticleSectionData.contents,
      featureArticles: featureArticleSectionData.contents,
      categories: categories.contents,
      tags: tags.contents,
    },
    revalidate: 10 * 60,
  }
}

const themeWithMediaCarouselStyle = extendTheme(baseTheme, {
  styles: {
    global: {
      ".swiper-pagination-bullet": {
        backgroundColor: "moneiro-color-fill-gray-sub",
        opacity: 1,
        boxSize: "9px",
      },
      ".swiper-pagination-bullet-active": {
        backgroundColor: "moneiro-color-main-orange",
      },
      ".swiper-button-prev, .swiper-button-next": {
        backgroundColor: "moneiro-color-main-orange",
        boxSize: "46px",
        opacity: 0.7,
        borderRadius: "100%",
      },
      ".swiper-button-prev": {
        left: { md: "26%" },
      },
      ".swiper-button-next": {
        right: { md: "25%" },
      },
      ".swiper-button-prev:after, .swiper-button-next:after": {
        color: "white",
        fontSize: "24px",
      },
    },
  },
})

setPageAttr(MediaPage, {
  noAuth: true,
  getLayout: createGetMediaPageLayout({ noInfo: true }),
  customTheme: themeWithMediaCarouselStyle,
})
