import { httpFetch } from "@~/common/lib/fetch"
import { Entry } from "@~/common/lib/microcms/media"
import { Category } from "@~/common/lib/microcms/media/types/categories"
import { Tag } from "@~/common/lib/microcms/media/types/tags"
import { Overwrite } from "@~/common/types/util"
import { useState } from "react"
import useSWR from "swr"

type RecommendedEntry = Overwrite<
  Pick<Entry, "id" | "eyecatch" | "title" | "publishedAt" | "createdAt">,
  {
    authors: { name: string }[]
    category?: Pick<Category, "name">
    tags?: Pick<Tag, "id" | "name">[]
  }
>

const getRecommendedEntriesByCategory = async (
  categoryId: string
): Promise<RecommendedEntry[]> => {
  const res = await httpFetch(
    `/api/media/category/${categoryId}/recommended-entries`,
    { method: "GET" }
  )
  return await res.json()
}

export const useRecommendedEntriesByCategory = (initialCategoryId: string) => {
  const [targetCategoryId, setTargetCategoryId] = useState(initialCategoryId)

  const { data, error } = useSWR(
    targetCategoryId,
    async (key) => await getRecommendedEntriesByCategory(key)
  )

  return {
    data,
    error,
    targetCategoryId,
    setTargetCategoryId,
  }
}
