import { ChevronRightIcon } from "@chakra-ui/icons"
import { Box, Button, Center, chakra } from "@chakra-ui/react"
import { Author } from "@~/common/lib/microcms/media"
import { pagesPath } from "lib/$path"
import NextLink from "next/link"
import { AuthorGrid } from "../author/AuthorGrid"
import { PageTitle } from "../common/PageTitle"

export type AuthorsSectionProps = {
  authors: Pick<Author, "name" | "title" | "icon" | "id">[]
  id?: string
}

export const AuthorsSection = ({ authors, ...props }: AuthorsSectionProps) => {
  return (
    <chakra.section px={3} py={{ base: 8, md: 10 }} bgColor="white">
      <PageTitle id={props.id} mainText="執筆者・監修者" />
      <AuthorGrid
        authors={authors}
        mt={9}
        maxW="container.content"
        mx="auto"
        px={2}
        cellSize="m"
      />
      <Center mt={8}>
        <NextLink legacyBehavior href={pagesPath.media.author.$url()} passHref>
          <Button
            as="a"
            size="lg"
            w="full"
            maxW="315px"
            colorScheme="secondary"
            justifyContent="space-between"
            leftIcon={<Box boxSize="24px" />}
            rightIcon={<ChevronRightIcon boxSize="24px" />}
          >
            執筆者・監修者一覧へ
          </Button>
        </NextLink>
      </Center>
    </chakra.section>
  )
}
