import NextLink from "next/link"

import { pagesPath } from "lib/$path"
import { EntryList } from "pages/api/media/entry/_index"

import { ChevronRightIcon } from "@chakra-ui/icons"
import { Box, Button, chakra, SimpleGrid, VStack } from "@chakra-ui/react"
import { Visible } from "components/system"
import { Fragment } from "react"
import { ArticleCard, EmphasizedArticleCard } from "../ArticleCard"
import { ArticleSimpleCard } from "../ArticleSimpleCard"

export type LatestEntriesSectionProps = {
  latestEntries: EntryList["contents"]
}

export const LatestEntriesSection = ({
  latestEntries,
}: LatestEntriesSectionProps) => {
  if (latestEntries.length === 0) {
    return (
      <chakra.div
        textStyle={{ base: "14-reg", md: "16-reg" }}
        textAlign="center"
      >
        該当のカテゴリーの記事が存在しません
      </chakra.div>
    )
  }

  const [firstEntry, ...restEntries] = latestEntries

  return (
    <VStack spacing={4}>
      <EmphasizedArticleCard
        key={firstEntry.id}
        entry={firstEntry}
        w="full"
        maxW="container.content"
        mx="auto"
        marginBottom={{ base: 0, md: 4 }}
      />
      <SimpleGrid
        columns={{ base: 1, md: 3 }}
        spacing={5}
        w="full"
        maxW="container.content"
        mx="auto"
      >
        {restEntries.map((re) => (
          <Fragment key={re.id}>
            <Visible baseOnly>
              <ArticleSimpleCard entry={re} showPublishedAt={true} />
            </Visible>
            <Visible mdOnly>
              <ArticleCard entry={re} />
            </Visible>
          </Fragment>
        ))}
      </SimpleGrid>

      <NextLink href={pagesPath.media.article.$url()} passHref legacyBehavior>
        <Button
          as="a"
          size="lg"
          w="full"
          maxW="315px"
          colorScheme="secondary"
          justifyContent="space-between"
          leftIcon={<Box boxSize="24px" />}
          rightIcon={<ChevronRightIcon boxSize="24px" />}
        >
          記事一覧へ
        </Button>
      </NextLink>
    </VStack>
  )
}
