import { ChevronRightIcon } from "@chakra-ui/icons"
import { LinkOverlay } from "@chakra-ui/react"
import { LinkBox } from "@chakra-ui/react"
import {
  AspectRatio,
  Box,
  Button,
  Circle,
  Image,
  Stack,
  VStack,
  chakra,
} from "@chakra-ui/react"
import { imgixURL } from "@~/common/lib/imgix"
import { FeatureEntry } from "@~/common/lib/microcms/media"
import { joinJSXNode } from "@~/common/lib/react/join"
import { pagesPath } from "lib/$path"
import NextLink from "next/link"

export type FeatureEntryListCardProps = {
  entry: Pick<FeatureEntry, "id" | "title" | "description" | "thumbnail">
  /** 表示の際の「何番目のカードか」を示す */
  cardIndex: number
  /** v1: 正方形に近いバージョン, v2: 横長のバージョンでボタンが青い */
  variant?: "v1" | "v2"

  /** タイトル部分のレベル default: p */
  level?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p"
}

export const FeatureEntryListCard = ({
  entry,
  cardIndex,
  variant,
  level,
}: FeatureEntryListCardProps) => {
  return variant === "v2" ? (
    <FeatureEntryListCardVariant2
      entry={entry}
      cardIndex={cardIndex}
      level={level}
    />
  ) : (
    <FeatureEntryListCardVariant1
      entry={entry}
      cardIndex={cardIndex}
      level={level}
    />
  )
}

const FeatureEntryListCardVariant1 = ({
  entry,
  cardIndex,
  level,
}: FeatureEntryListCardProps) => {
  return (
    <Stack
      direction="column"
      border="1px solid"
      borderColor="moneiro-color-line-gray-basic"
      spacing={{ base: 3, md: 4 }}
      p="16px"
      rounded={10}
      maxW={{ base: "full", md: "363px" }}
      h="full"
      w="full"
      bg={"white"}
    >
      {/* 画像 */}
      <AspectRatio w="full" ratio={380 / 200}>
        <Image
          src={imgixURL(
            entry.thumbnail?.url,
            `w=${380 * 2}&fit=crop&crop=faces`
          )}
          alt={entry.title}
          objectFit="cover"
          borderRadius={{ base: "5px", md: "5.5px" }}
          ignoreFallback={!!entry.thumbnail}
          fallback={
            <Box
              bgColor="moneiro-color-fill-gray-basic"
              borderRadius={{ base: "5px", md: "m" }}
            />
          }
        />
      </AspectRatio>

      <VStack w="full" align="normal" flexGrow={1} spacing={0}>
        {/* インデックス */}
        <chakra.span
          textAlign={"right"}
          fontSize={{ base: "64px", md: "70px" }}
          fontWeight={700}
          textColor={"#F4F4F4"}
          mt={{ base: -4, md: -5 }}
          // フォントの視覚上の重心を考慮しつつ右に揃える
          mr={cardIndex === 1 ? -3 : -1}
          mb={{ base: -14, md: -16 }}
        >
          {cardIndex < 10 ? `0${cardIndex}` : cardIndex}
        </chakra.span>

        {/* タイトル */}
        <chakra.p as={level ?? "p"} textStyle="f18Bold">
          {entry.title}
        </chakra.p>

        {/* 説明 */}
        <chakra.p textStyle="f14" whiteSpace="pre-wrap" mt={1} mb={2}>
          {entry.description}
        </chakra.p>

        {/* 「詳しく見る」ボタン */}
        <Button
          size="mid"
          maxW="230px"
          rightIcon={
            <Circle bg={"moneiro-color-main-orange"}>
              <ChevronRightIcon boxSize="17px" color={"white"} />
            </Circle>
          }
          mt={"auto"}
          mx={{ base: "auto", md: "auto" }}
          variant={"link"}
          textColor={"moneiro-color-sub-orange"}
        >
          詳しく見る
        </Button>
      </VStack>
    </Stack>
  )
}

const FeatureEntryListCardVariant2 = ({
  entry,
  level,
}: FeatureEntryListCardProps) => {
  return (
    <LinkBox
      as="article"
      border="1px solid"
      borderColor="moneiro-color-line-gray-basic"
      p="16px"
      rounded={10}
      h="full"
      w="full"
      bg={"white"}
      display={"flex"}
      flexDirection={"row"}
      flexWrap={{ base: "wrap", md: "nowrap" }}
      justifyContent={{ base: "center", md: "flex-start" }}
      gap={{ base: 3, md: 4 }}
    >
      {/* 画像 */}
      <chakra.div>
        <AspectRatio w="380px" ratio={380 / 200} maxW={380}>
          <Image
            src={imgixURL(
              entry.thumbnail?.url,
              `w=${380 * 2}&h=${200 * 2}&fit=crop&crop=faces`
            )}
            alt={entry.title}
            objectFit="cover"
            borderRadius={{ base: "5px", md: "5.5px" }}
            ignoreFallback={!!entry.thumbnail}
            fallback={
              <Box
                bgColor="moneiro-color-fill-gray-basic"
                borderRadius={{ base: "5px", md: "m" }}
              />
            }
          />
        </AspectRatio>
      </chakra.div>

      <VStack align="normal" spacing={0}>
        {/* タイトル */}
        <LinkOverlay
          as={NextLink}
          /** @ts-expect-error */
          href={pagesPath.media.feature._id(entry.id).$url()}
          zIndex={1}
        >
          <chakra.strong as={level ?? "strong"} textStyle="f18Bold">
            {entry.title}
          </chakra.strong>
        </LinkOverlay>

        {/* 説明 */}
        <chakra.p textStyle="f14" mt={1}>
          {joinJSXNode(entry.description.split("\n"), <br />)}
        </chakra.p>

        {/* 「詳しく見る」ボタン */}
        <chakra.div pt={5} mt={"auto"} mb={3}>
          <Button
            maxW={{ base: "full", md: "230px" }}
            width={"full"}
            shadow={"md"}
            zIndex={0} // fake button
            colorScheme="secondary"
            justifyContent="space-between"
            leftIcon={<Box boxSize="24px" />}
            rightIcon={<ChevronRightIcon boxSize="24px" />}
            as="span"
          >
            詳しく見る
          </Button>
        </chakra.div>
      </VStack>
    </LinkBox>
  )
}
