import { staticPath_assets_illust_woman_inspired_png } from "lib/$asset-path"
import {
  Box,
  Button,
  Center,
  chakra,
  Image,
  Link,
  Stack,
} from "@chakra-ui/react"
import { pagesPath } from "lib/$path"

import NextLink from "next/link"
import { PageTitle } from "../common/PageTitle"
import { getFeatureEntryPagePath } from "../entry"
import {
  FeatureEntryListCard,
  FeatureEntryListCardProps,
} from "../feature-entry/FeatureEntryListCard"
import { ChevronRightIcon } from "@chakra-ui/icons"

export type FeatureArticleSectionProps = {
  id?: string
  entries: FeatureEntryListCardProps["entry"][]
}

export const FeatureArticleSection = ({
  entries,
  ...props
}: FeatureArticleSectionProps) => {
  return (
    <chakra.section
      bgColor="moneiro-color-fill-pale-yellow"
      px={3}
      pt={8}
      pb={{ base: 8, md: 10 }}
      borderRadius={{ base: undefined, md: "30px" }}
      w="full"
      maxW="container.content"
      alignSelf="center"
    >
      {/* タイトル・ロゴ・説明 */}
      <PageTitle mainText="特集" mb={{ base: 4, md: 2 }} />
      <chakra.div
        display={"flex"}
        justifyContent={"right"}
        mb={{ base: undefined, md: -2 }}
      >
        <Image
          alt=""
          src={staticPath_assets_illust_woman_inspired_png}
          w={{ base: "100px", md: "128px" }}
          h={{ base: "103px", md: "135px" }}
          mt={{ base: -125, md: -130 }}
          mr={{ base: 2, md: 4 }}
          mb={{ base: -1, md: -3 }}
        />
      </chakra.div>
      <chakra.div
        px={{ base: 4, md: 3 }}
        pt={{ base: 0, md: 0 }}
        pb={{ base: 4, md: 0 }}
        textAlign={{ base: "left", md: "center" }}
      >
        <chakra.p
          fontSize={"14px"}
          w="full"
          maxW="container.secondContent"
          mx="auto"
          px={{ base: 0, md: 3 }}
          mt={{ base: 0, md: 5 }}
          mb={{ base: 0, md: 2 }}
        >
          「NISAとiDeCoの違いは？」「運用商品はどう選べば良い？」など、資産運用や投資に関するお悩みをサクッと解決する特集記事です。
        </chakra.p>
      </chakra.div>

      {/* 特集記事一覧 */}
      <chakra.div pt={{ base: 0, md: 2 }} id={props.id} justifyContent="center">
        <Stack
          direction={{ base: "column", md: "row" }}
          spacing={4}
          w="full"
          mx={{ base: "auto", md: 5 }}
        >
          {entries.map((entry, i) => (
            <Link
              key={entry.id}
              as={NextLink}
              href={getFeatureEntryPagePath(entry)}
              _hover={{ opacity: 0.8 }}
              alignSelf={"stretch"}
            >
              <FeatureEntryListCard
                entry={entry}
                cardIndex={i + 1}
                level="h3"
              />
            </Link>
          ))}
        </Stack>
      </chakra.div>

      <Center pt={6}>
        <NextLink href={pagesPath.media.feature.$url()} passHref legacyBehavior>
          <Button
            as="a"
            size="lg"
            w="full"
            maxW="315px"
            colorScheme="secondary"
            justifyContent="space-between"
            leftIcon={<Box boxSize="24px" />}
            rightIcon={<ChevronRightIcon boxSize="24px" />}
          >
            特集一覧へ
          </Button>
        </NextLink>
      </Center>
    </chakra.section>
  )
}
