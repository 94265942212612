import React from "react"

/** `nodes`を`separator`でjoinします */
export const joinJSXNode = (
  nodes: React.ReactNode[],
  separator: React.ReactNode
) => {
  const separatorIsElement = React.isValidElement(separator)
  return nodes.flatMap((node, idx) => {
    const hasNext = typeof nodes[idx + 1] !== "undefined"
    return hasNext
      ? [
          node,
          separatorIsElement
            ? React.cloneElement(separator, { key: `sep-${idx}` })
            : separator,
        ]
      : node
  })
}
