import { chakra, Flex, FlexProps, LinkProps, VStack } from "@chakra-ui/react"
import { Author } from "@~/common/lib/microcms/media"
import { pagesPath } from "lib/$path"
import NextLink from "next/link"
import { CMSImageAvatar } from "../Avatar"

type AuthorGridProps = {
  authors: Pick<Author, "id" | "name" | "icon" | "title">[]
  cellSize?: "l" | "m"
} & FlexProps

const AuthorCellPropsWithSize = {
  l: {
    containerDirection: "row",
    w: { base: 32, md: 36 },
    imageSize: { base: 124, md: 140 },
    authorTitleTextStyle: "12-reg",
    authorInfoAlign: "initial",
    cellFlexDirection: "column",
    cellGap: 0,
  },
  m: {
    containerDirection: { base: "column", md: "row" },
    w: { base: "full", md: 20 },
    imageSize: { base: "72px", md: 100 },
    authorTitleTextStyle: "10-reg",
    authorInfoAlign: { base: "flex-start", md: "initial" },
    cellFlexDirection: { base: "row", md: "column" },
    cellGap: { base: 4, md: 0 },
  },
} as const

/** 執筆者のアイコン一覧表示 */
export function AuthorGrid({
  authors,
  cellSize = "l",
  ...props
}: AuthorGridProps) {
  const cellProps = AuthorCellPropsWithSize[cellSize]

  return (
    <Flex
      columnGap={{ base: 2, md: 7 }}
      rowGap={{ base: 5, md: 10 }}
      mt={9}
      justify="center"
      flexWrap={"wrap"}
      maxW="container.content"
      mx="auto"
      direction={cellProps.containerDirection}
      {...props}
    >
      {authors.map((author) => {
        return (
          <NextLink
            key={author.id}
            href={pagesPath.media.author._author_id(author.id).$url()}
            passHref
            legacyBehavior
          >
            <chakra.a
              color={"mechoiceBlack"}
              w={cellProps.w}
              display="flex"
              flexDirection={cellProps.cellFlexDirection}
              gap={cellProps.cellGap}
            >
              <CMSImageAvatar
                alt={author.name}
                size={cellProps.imageSize}
                sizes="(max-width: 480px) 50vw, (max-width: 767px) 33vw, 10vw"
                _div={{
                  gridArea: "img",
                  mx: cellSize === "l" ? "auto" : 0,
                  flex: "none",
                }}
                _image={{
                  src: author.icon!?.url,
                }}
              />
              <VStack spacing={0} alignItems={cellProps.authorInfoAlign}>
                <chakra.h3 mt={"13px"} textStyle={"14-bold"} textAlign="center">
                  {author.name}
                </chakra.h3>
                <chakra.p
                  mt={"2px"}
                  textAlign="center"
                  textStyle={cellProps.authorTitleTextStyle}
                >
                  {author.title}
                </chakra.p>
              </VStack>
            </chakra.a>
          </NextLink>
        )
      })}
    </Flex>
  )
}
