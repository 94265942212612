import { BoxProps, chakra } from "@chakra-ui/react"
import { imgixSquareRoundedImageLoader } from "@~/common/lib/imgix"
import { NextImage, NextImageProps } from "components/system"

export const CMSImageAvatar = (props: {
  _image: NextImageProps
  _div: BoxProps
  alt: string
  size: BoxProps["width"]
  sizes?: string
}) => {
  return (
    <chakra.div
      {...props._div}
      gridArea={"img"}
      width={props.size}
      height={props.size}
      pos="relative"
    >
      <NextImage
        objectFit={"cover"}
        layout="fill"
        {...props._image}
        loader={imgixSquareRoundedImageLoader}
        alt={props.alt}
        sizes={props.sizes}
        sx={{
          borderRadius: "full",
        }}
      />
    </chakra.div>
  )
}
