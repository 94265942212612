import { chakra } from "@chakra-ui/react"

export const MediaPageTitle = () => (
  <chakra.section pt={{ base: 5, md: 5 }} pb={1}>
    <chakra.h1
      textStyle={{ base: "f20Bold", md: "f30Bold" }}
      textAlign="center"
      mb={{ base: 3, md: 4 }}
    >
      <chakra.span
        display="block"
        fontSize={{ base: "11px", md: "16px" }}
        color="moneiro-color-main-orange"
        lineHeight={1.5}
        letterSpacing={"0.05em"}
      >
        お金のわからないを解決する
      </chakra.span>
      <chakra.span
        fontSize={{ base: "20px", md: "30px" }}
        fontWeight={700}
        letterSpacing={"0.04em"}
      >
        マネイロメディア
      </chakra.span>
    </chakra.h1>
  </chakra.section>
)
