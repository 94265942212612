import { chakra } from "@chakra-ui/react"
import { EntryList } from "pages/api/media/entry/_index"
import { LatestEntriesSection } from "../common/EntryListSection/LatestEntriesSection"

export type LatestArticleSectionProps = {
  id?: string
  latestEntries: EntryList["contents"]
}

export const LatestArticleSection = ({
  ...props
}: LatestArticleSectionProps) => {
  return (
    <chakra.section
      px={3}
      pb={{ base: 8, md: 6 }}
      // 記事取得中のShimmerが無いので、とりあえず適当に高さを確保
      minHeight={690}
    >
      <LatestEntriesSection latestEntries={props.latestEntries} />
    </chakra.section>
  )
}
